<template>
  <div class="user-answers">
    <div class="user-answers__header">
      <h3 class="user-answers__title">
        Réponses de {{user.firstname + ' ' + user.lastname}}
      </h3>
      <p class="user-answers__description">
        {{user.job}}
      </p>
    </div>
    <div class="user-answers__body">
      <dl class="user-answers__divider">
        <div v-for="(answer, index) in user.answers" :key="index" class="user-answers__item">
          <dt class="user-answers__question">{{answer.question}}</dt>
          <dd class="user-answers__answer">
            {{answer.answer ? answer.answer : 'Aucune réponse'}}
          </dd>
        </div>
      </dl>
    </div>
    <div class="user-answers__cta">
      <atom-button variant="danger md" @click="updateUser(false)">Rejeter</atom-button>
      <atom-button variant="success md" @click="updateUser(true)">Approuver</atom-button>
    </div>
  </div>
</template>

<script setup>
import {toRefs, computed } from "vue";
import {useStore} from "vuex";
const emit = defineEmits(['modalClose'])
const {dispatch} = useStore();
const props = defineProps({
  user:{
    type:Object,
    required:true
  }
});
const {user} = toRefs(props);
const userCommunityName = computed( () => {
  switch(user.value.job){
    case 'journaliste':
      return 'journalistes';
    case 'parlementaire':
      return 'parlementaires';
    case 'chercheur':
      return 'chercheurs';
    case 'La société civile':
      return 'société civile';
  }
});
const updateUser = async (activate) => {
  const {id} = user.value;
  // Approve user
  if(activate){
    try {
      // Activate the user
      const newUser = await dispatch('users/update', {
        id:id,
        status:'activated',
        role:'client',
        });
      // Get community id 
      const {id:communityId} = await dispatch('communities/getByName', userCommunityName.value);
      // Add the user to the appropriate community 
      const newCommunity = await dispatch('communities/addMember', {
        userId: id,
        communityId
      });
      emit('modalClose');
    } catch (err) {
      // const {message} = err.response.data;
      console.log(err);
    }
  }
  // Reject user
  else{
    try {
      // Activate the user
      const newUser = await dispatch('users/update', {
        id:id,
        status:'rejected'
        });
      emit('modalClose');
    } catch (err) {
      // const {message} = err.response.data;
      console.log(err);
    }
  }
}
</script>

<style lang="scss" scoped>
.user-answers{
  &__header{
    @apply px-4 py-5 sm:px-6;
  }
  &__title{
    @apply text-lg leading-6 font-medium text-gray-900;
  }
  &__description{
    @apply mt-1 max-w-2xl text-sm text-gray-500 capitalize;
  }
  &__body{
    @apply border-t border-gray-200 px-4 py-5 sm:p-0;
  }
  &__divider{
    @apply sm:divide-y sm:divide-gray-200;
  } 
  &__item{
    @apply py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6;
  }
  &__question{
    @apply text-sm font-medium text-gray-500;
  }
  &__answer{
    @apply mt-1 text-sm text-gray-900 sm:mt-0;
  }
  &__cta{
    @apply flex justify-end gap-3 mt-2;
  }
}
</style>