<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste des requêtes">
      <block-registration-requests-table
      @showAnswers="showAnswers($event)"
      >
      </block-registration-requests-table>
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="isModalOpen = false">
      <block-user-answers :user="user" @modalClose="isModalOpen = false"></block-user-answers>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockRegistrationRequestsTable from "@/components/blocks/tables/BlockRegistrationRequestsTable.vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockNewsForm from "@/components/blocks/forms/BlockNewsForm.vue";
import BlockUserAnswers from "@/components/blocks/BlockUserAnswers.vue";
import {ref} from "vue";
const isModalOpen =ref(false);
const user = ref(null);
const showAnswers = (payload) => {
  isModalOpen.value = true;
  user.value = payload;
}
</script>

<style lang="scss" scoped></style>
