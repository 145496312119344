<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="users-table">
            <thead class="users-table__header">
              <tr>
                <th scope="col" class="users-table__header-col">Nom</th>
                <th scope="col" class="users-table__header-col">Intitule</th>
                <th scope="col" class="users-table__header-col">Status</th>
                <th scope="col" class="users-table__header-col">Role</th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Modifier</span>
                </th>
              </tr>
            </thead>
            <tbody class="users-table__body">
              <tr
                class="users-table__row"
                v-for="person in users"
                :key="person.email"
              >
                <td class="users-table__row-cel">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        v-if="person.avatar"
                        class="h-10 w-10 rounded-full object-cover"
                        :src="imageUrl(person.avatar)"
                        alt=""
                      />
                      <div v-else
                        class="h-10 w-10 rounded-full bg-primary flex justify-center items-center"
                      >
                        <span class="uppercase text-white text-base font-semibold tracking-widest">{{person?.firstname[0] + person?.lastname[0]}}</span>
                      </div>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ person.firstname }} {{person.lastname}}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ person.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="users-table__row-cel">
                  <div class="text-sm text-gray-900">{{ person.job }}</div>
                  <div class="text-sm text-gray-500">
                    {{ person.department }}
                  </div>
                </td>
                <td class="users-table__row-cel">
                  <span :class="statusClassName(person.status)">
                    {{ person.status == 'pending' ? 'En attente' : 'Actif'}}
                  </span>
                </td>
                <td class="users-table__row-cel text-sm text-gray-500">
                  {{ userRole(person.role) }}
                </td>
                <td class="users-table__row-cel text-right text-sm font-medium">
                  <a @click.prevent="showAnswers(person)" class="text-primary hover:text-primary/90 mr-2 cursor-pointer"
                    >Voir les réponses</a
                  >

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, computed, inject } from "vue";
import {useStore} from "vuex";
const emit = defineEmits(['showAnswers']);
const {dispatch, state} = useStore();
const users = computed( () => state.users.users);

onBeforeMount( async () => {
  try {
    await dispatch('users/getByStatus', 'pending');
  } catch (err) {
    console.log(err.response.data);
  }
});
const userRole = (role) =>{
  switch(role){
    case 'admin':
      return 'Administrateur';
    case 'client':
      return 'Utilisateur';
    case 'guest':
      return 'Invité';
  }
}

const statusClassName = (status) => {
  switch (status) {
    case "activated":
      return "users-table__row-cel-status users-table__row-cel-status--active";
    case "pending":
      return "users-table__row-cel-status users-table__row-cel-status--pending";
    case "blocked":
      return "users-table__row-cel-status users-table__row-cel-status--blocked";
    default:
      return "users-table__row-cel-status";
  }
};
const showAnswers = (user) => {
  emit('showAnswers', user);
}
const imageUrl = inject('imageUrl');
</script>

<style lang="scss" scoped>
.users-table {
  @apply min-w-full divide-y divide-gray-200;
  &__header {
    @apply bg-gray-50;
    &-col {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }
  &__body {
    @apply bg-white divide-y divide-gray-200;
  }
  &__row {
    &-cel {
      @apply px-6 py-4 whitespace-nowrap;
      &-status {
        @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
        &--active {
          @apply bg-green-100 text-green-600;
        }
        &--blocked {
          @apply bg-red-100 text-red-600;
        }
        &--pending {
          @apply bg-gray-100 text-gray-600;
        }
      }
    }
  }
}
</style>
